<template>
  <div class="recruitWrap" v-loading="loadShow">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="recruitBar mb20" :style="{backgroundImage: 'url('+recruitBg+')'}">
          <div class="recruitMain">
            <div class="title mb20"><img :src="title" alt="招募中心"></div>
            <div class="inviteWrap">
              <span class="txt">邀请的好友数量</span>
              <span class="num">{{count}}位</span>
              <span class="myInviteCode">我的邀请码：{{myInviteCode}}</span>
            </div>
            <div class="taskWrap">
              <div class="item" v-for="(item,index) in taskList">
                <div class="img" v-if="item.taskType"><img :src="require('@/assets/imgs/recruit/icon'+item.taskType+'.png')" alt=""></div>
                <div class="pic"><img :src="require('@/assets/imgs/recruit/recruit'+ (index%3) +'.png')" alt=""></div>
                <div class="taskMain">
                  <div class="taskMainBar">
                    <div class="taskMainL">
                      <div class="name">{{item.taskName}}（{{item.process}}）</div>
                      <div class="nameDes">{{item.descprition}}</div>
                    </div>
                    <div class="taskMainR">
                      <img height="60" width="60" :src="item.taskPrizeImg" alt="">
                      <span class="count">{{item.prizeNum}}</span>
                    </div>
                  </div>
                </div>
                <div class="btnWrap">
                  <span class="btn" v-if="item.isClick == 1" @click="receive(item)">{{item.btnText}}</span>
                  <span class="btn btn1" v-else>{{item.btnText}}</span>
                </div>
              </div>
            </div>
            <div class="rulesWrap">
              <div class="tit">规则说明：</div>
              <div class="cons">
                <p>1）招募奖励可以累计获取，但每种仅限一次。</p>
                <p>2）完成高级招募任务 — 登堂入室可获得挚友徽章，领取后请于游戏中角色徽章栏查收。</p>
                <p>3）完成高级招募任务 — 土豪朋友可获得顶流徽章，领取后请于游戏中角色徽章栏查收。</p>
                <p>4）完成高级招募任务 — 鸿运当头可获得鸿运徽章，领取后请于游戏中角色徽章栏查收。</p>
                <p>5）挚友徽章、顶流徽章、小灰灰为招募专属奖励。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'
  import axios from "axios";
  import Cookies from "js-cookie";
  export default {
    name: 'recruit',
    components:{
      menuWX,
      downItemWX,
    },
    data(){
      return {
        loadShow:false,
        recruitBg:require('@/assets/imgs/recruit/recruitBg.png'),
        title:require('@/assets/imgs/recruit/title.png'),
        count:10,
        myInviteCode:'jdhsjdhs',
        taskList:[
          {
            pic:require('@/assets/imgs/recruit/recruit0.png'),
            name:'携手共进',
            count:0,
            total:15,
            nameDes:'好友首次登录',
            rewardCon:'领取',
          },
          {
            pic:require('@/assets/imgs/recruit/recruit1.png'),
            name:'小有薄财',
            count:0,
            total:1,
            nameDes:'好友累积充值满100元',
            rewardCon:'领取',
          },
          {
            pic:require('@/assets/imgs/recruit/recruit2.png'),
            name:'可靠的伙伴',
            count:0,
            total:5,
            nameDes:'好友升至40级',
            rewardCon:'领取',
          },
          {
            pic:require('@/assets/imgs/recruit/recruit3.png'),
            name:'豪友',
            count:0,
            total:1,
            nameDes:'好友累积充值满1000元',
            rewardCon:'领取',
          },
        ]
      }
    },
    created(){
      this.getRecruitTaskList()
    },
    mounted(){
      document.documentElement.scrollTop = 0
    },
    methods: {
      getRecruitTaskList(){
        this.loadShow = true
        axios({
          method: 'post',
          url: '/task/getRecruitTaskList',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            platForm:'web',
            userId: Cookies.get('wx_userId'),      // userId
          },
        }).then((res) => {
          this.loadShow = false
          if(res.data.state == 200){
            this.count = res.data.data.counts
            this.myInviteCode = res.data.data.inviteCode
            this.taskList = res.data.data.list
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },      // 获取招募列表 - 任务管理
      receive(item){
        console.log(item);
        axios({
          method: 'post',
          url: '/task/getRecruitTaskPrize',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            taskId:item.taskId,
            platForm:'web',
            userId: Cookies.get('wx_userId'),      // userId
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.$message.success(res.data.message)
            this.getRecruitTaskList()
            this.getUserInfo()
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
      goShop(item){
        if(item.btnUrl.indexOf('http')!=-1){
          window.open(item.btnUrl,'_blank')
        }else{
          this.$router.push({
            path:item.btnUrl,
          })
        }
      },           // 去购买 - 进入商城盲盒页面
      getUserInfo(){// 获取用户信息
        axios({
          method: 'post',
          url: '/user/getMemberInfo',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            platForm:'web',
            userId:Cookies.get('wx_userId')
          },
        }).then(res => {
          if(res.data.state == 200){
            if(res.data&&res.data.data){
              this.userInfo=res.data.data
              this.resetSetItem('userInfo', JSON.stringify(res.data.data))
            }
          }else{
            this.$message.error(res.data.message)
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .recruitWrap{
    .recruitBar{
      padding:40px 40px 50px 40px;
      background-size: auto 100%;
      box-sizing: border-box;
      background-repeat: no-repeat;
      .recruitMain{
        .title{
          text-align: center;
        }
        .inviteWrap{
          margin-bottom: 28px;
          height: 44px;
          line-height: 44px;
          border: 1px solid #D22190;
          box-sizing: border-box;
          overflow: hidden;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          span{
            font-size: 20px;
          }
          .txt{
            margin-right: 88px;
            padding-left:10px;
          }
          .myInviteCode{
            padding:0 15px;
            float: right;
            color: #ffffff;
            background: #D22190;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
        .taskWrap{
          min-height: 442px;
          padding-bottom: 10px;
          box-sizing: border-box;
          .item{
            position: relative;
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;
            padding:7px 20px 7px 45px;
            background: rgba(255,255,255,0.5);
            box-shadow: 0 4px 10px 0 rgba(135,135,135,0.25);
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            height: 100px;
            .img{
              position: absolute;
              left: -1px;
              top: -1px;
            }
            .pic{
              margin-right: 30px;
              width: 90px;
              height: 82px;
              text-align: center;
              img{
                max-width: 90px;
                height:100%;
              }
            }
            .taskMain{
              flex: 1;
              .taskMainBar{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .taskMainL{
                  .name{
                    margin-bottom: 20px;
                    font-size: 20px;
                    font-weight: 700;
                  }
                  .nameDes{
                    font-size: 17px;
                    font-weight: 700;
                  }
                }
                .taskMainR{
                  padding-right: 30px;
                  .count{
                    margin-top:5px;
                    display: block;
                    color: #D22190;
                    font-size: 12px;
                    font-weight: 700;
                    text-align: center;
                  }
                }
              }
            }
            .btnWrap{
              display: inline-block;
              height: 84px;
              line-height: 84px;
              .btn{
                display: inline-block;
                width: 126px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                color: #ffffff;
                font-size: 20px;
                background: #D22190;
                border-radius: 4px;
                vertical-align: middle;
                cursor: pointer;
              }
              .btn1{
                background: #808080;
                cursor: default;
              }
            }
          }
        }
        .rulesWrap {
          padding:20px;
          background: rgba(255,255,255,0.5);
          box-shadow: 0 4px 10px 0 rgba(135,135,135,0.25);
          border-radius: 4px;
          border: 1px solid #D22190;
          .tit{
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
          }
          .cons{
            line-height: 30px;
            p{
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>
